import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class VideoJSDataService {
  // for audio
  private fileSource = new BehaviorSubject("");
  sharedFileSource = this.fileSource.asObservable();

  // for hide and seek
  private isVisible = new BehaviorSubject(false);
  isPlayerVisible = this.isVisible.asObservable();

  private isReply = new BehaviorSubject(false);
  isReplyPlay = this.isReply.asObservable();

  // for replyName
  private replyName = new BehaviorSubject("");
  currentlyPlayingReplyName = this.replyName.asObservable();

  // for playlist
  private playListSource = new BehaviorSubject(null);
  playList = this.playListSource.asObservable();

  private currentItemIndex = new BehaviorSubject(null);
  currentItem = this.currentItemIndex.asObservable();

  private videoPlayer = new BehaviorSubject(null);
  currentVideoPlayer = this.videoPlayer.asObservable();

  constructor() {}

  updateFileSource(source?: string) {
    this.fileSource.next(source);
  }

  updateVisibility(visibility: boolean) {
    this.isVisible.next(visibility);
    if (!visibility) {
      this.playListSource.next(null);
    }
  }

  updateReplyName(name: string) {
    this.replyName.next(name);
  }

  addPlayList(playList: any, currentItemIndex: number) {
    this.currentItemIndex.next(currentItemIndex);
    this.playListSource.next(playList);
  }

  updateVideoPlayer(videoPlayer: any) {
    this.videoPlayer.next(videoPlayer);
  }

  updateReplyPlay(isReply: boolean) {
    this.isReply.next(isReply);
  }
}
