import { TranslateService } from "@ngx-translate/core";
import {
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Input, Output } from "@angular/core";
import { Component } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { Asset } from "@models/asset/asset.model";
import { Action } from "@models/shortcut/shortcut.model";
import { ProjectReply, VoiceType } from "@models/textToSpeech/project.model";
import { Subscription } from "rxjs";
import { splitTextIntoLines } from "~/utils/project";
import { ShortcutService } from "../../shortcut.service";
import {
  getTimeInSecondsFromFrameRate,
  secondsTimeToFramesStartTimeCode,
} from "~/utils/project";

@Component({
  selector: "app-reply-event",
  templateUrl: "./reply-event.component.html",
  styleUrls: ["./reply-event.component.scss"],
})
export class ReplyEventComponent implements OnInit, OnDestroy {
  @ViewChild("eventsEl") eventsEl: ElementRef;
  @Input() replyForm: FormGroup;
  @Input() isRecording = false;
  @Input() asset: Asset;
  @Input() voicerType: string;
  @Input() currentTimeCode: string;
  @Input() voicerReadingSpeed: number;
  @Input() frameRate: number;
  @Output() onEventChange = new EventEmitter();
  @Output() removeEvent = new EventEmitter();
  @Output() syncVideoAndTimeline = new EventEmitter();
  @Output() eventPlaybackChanged = new EventEmitter();
  @Output() selectEvent = new EventEmitter();

  public showCharacters = true;
  private selectedEventIndex = 0;

  public durationTextMask = {
    mask: [/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/],
  };
  private shortcutTriggeredSubscription: Subscription;

  constructor(
    private keyboardShortcutService: ShortcutService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.enableShortcuts();
  }

  ngOnDestroy(): void {
    this.shortcutTriggeredSubscription.unsubscribe();
  }

  enableShortcuts(): void {
    this.shortcutTriggeredSubscription =
      this.keyboardShortcutService.shortcuts$.subscribe((shortcut) => {
        if (shortcut === Action.InTimecode) {
          this.setInTimeCode();
        } else if (shortcut === Action.OutTimecode) {
          this.setOutTimeCode();
        } else if (shortcut === Action.PlayEvent) {
          this.playPauseEvent(this.selectedEventIndex);
        }
      });
  }

  setInTimeCode() {
    setTimeout(() => {
      const eventGroup = this.events.at(this.selectedEventIndex) as FormGroup;
      eventGroup.get("tcIn").patchValue(this.currentTimeCode);
      this.onRetakeChange(this.selectedEventIndex);
    }, 100);
  }

  setOutTimeCode() {
    setTimeout(() => {
      const eventGroup = this.events.at(this.selectedEventIndex) as FormGroup;
      eventGroup.get("tcOut").patchValue(this.currentTimeCode);
      this.onRetakeChange(this.selectedEventIndex);
    }, 100);
  }

  disableShortcuts(): void {
    this.shortcutTriggeredSubscription.unsubscribe();
    this.keyboardShortcutService.destroyShortcutsListener();
    this.keyboardShortcutService.loadUserShortcuts();
    this.keyboardShortcutService.setupUserShortcuts();
  }

  public processIndex(index: number): string | number {
    return index < 10 ? "0" + index : index;
  }

  public splitEvent(event: string) {
    return event?.length > 75 ? splitTextIntoLines(event, 75) : [event];
  }

  get events() {
    return this.replyForm.get("events") as FormArray;
  }

  public deleteEvent(i: number): void {
    this.removeEvent.emit(i);
  }

  public selectEventToRecord(index: number, focusTextarea = true): void {
    this.selectedEventIndex = index;
    this.events.controls.forEach((item) => {
      item.get("isSelected").setValue(false);
    });
    /* if (
      this.events.controls[index].get("tcIn").value !== "00:00:00:00" &&
      this.events.controls[index].get("tcOut").value !== "00:00:00:00" &&
      this.events.controls[index].get("ttmlSegmentData").value
    ) {
      this.selectEvent.emit(index);
    }*/
    this.selectEvent.emit(index);
    this.events.controls[index].get("isSelected").setValue(true);
    setTimeout(() => {
      const activeElement = this.eventsEl.nativeElement.children[index];
      if (activeElement && focusTextarea) {
        activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        const textarea =
          this.eventsEl.nativeElement.children[index].querySelector(
            "#textEvent"
          );
        textarea.focus();
      }
    }, 100);
  }

  public onRetakeChange(index: number): void {
    const reply = this.events.controls[index].value;
    if (
      reply.tcIn !== "00:00:00:00" &&
      reply.tcOut !== "00:00:00:00"
      // reply.ttmlSegmentData
    ) {
      this.onEventChange.emit({ index, reply });
    }
  }

  public playPauseEvent(index: number): void {
    if (!this.isEventPlayableOnTimeline(index)) {
      return;
    }
    if (index !== this.selectedEventIndex) {
      this.syncVideoAndTimeline.emit({
        action: "pause",
        seekTime: this.events.controls[index].value.tcIn,
        index: this.selectedEventIndex,
      });
      this.selectedEventIndex = index;
      this.eventPlaybackChanged.emit();
    }
    this.syncVideoAndTimeline.emit({
      action: !this.events.controls[index].value.isBeingPlayed
        ? "play"
        : "pause",
      seekTime: this.events.controls[index].value.tcIn,
      index: index,
    });
  }

  public getWordsPerEvent(reply: ProjectReply): string {
    if (this.voicerType === VoiceType.Voicer) {
      const totalWords = reply.ttmlSegmentData.trim().length
        ? reply.ttmlSegmentData.trim().split(" ").length
        : 0;
      return `${totalWords} words`;
    } else {
      return this.getPossibleCharactersForSyntheticProject(
        reply.tcIn,
        reply.tcOut,
        reply.ttmlSegmentData
      );
    }
  }

  public hasExtraCharacters(reply: ProjectReply): boolean {
    const differenceInSeconds = this.getTimeCodeDifference(
      reply.tcIn,
      reply.tcOut
    );
    const differenceInMinutes = differenceInSeconds / 60;
    if (this.voicerType === VoiceType.Voicer) {
      const totalWords = reply.ttmlSegmentData.trim().length
        ? reply.ttmlSegmentData.trim().split(" ").length
        : 0;
      return totalWords > this.voicerReadingSpeed * differenceInMinutes;
    } else {
      const totalCharactersInDuration = Math.floor(differenceInSeconds * 15);
      return reply.ttmlSegmentData?.length > totalCharactersInDuration;
    }
  }

  private getTimeCodeDifference(tcIn: string, tcOut: string): number {
    return (
      getTimeInSecondsFromFrameRate(tcOut, this.frameRate) -
      getTimeInSecondsFromFrameRate(tcIn, this.frameRate)
    );
  }

  private getPossibleCharactersForSyntheticProject(
    tcIn: string,
    tcOut: string,
    ttmlSegmentData: string
  ): string {
    const differenceInSeconds = this.getTimeCodeDifference(tcIn, tcOut);
    if (differenceInSeconds <= 0) {
      return "";
    }
    const totalCharactersInDuration = Math.floor(differenceInSeconds * 15);
    const possibleCharacterCount =
      ttmlSegmentData?.length > totalCharactersInDuration
        ? `+${
            ttmlSegmentData.length - totalCharactersInDuration
          } ${this.translate.instant("createProject.extraCharacters")}`
        : totalCharactersInDuration - ttmlSegmentData?.length === 0
        ? ""
        : `${
            totalCharactersInDuration - ttmlSegmentData?.length
          } ${this.translate.instant("createProject.charactersRemaining")}`;
    return possibleCharacterCount;
  }

  public isEventPlayableOnTimeline(index: number): boolean {
    const event = this.events.controls[index].value;
    return (
      this.isEventWithinVideoDuration(event) &&
      this.timecodeToSeconds(event.tcIn) < this.timecodeToSeconds(event.tcOut)
    );
  }

  private timecodeToSeconds(timecode: string): number {
    const [hours, minutes, seconds, frames] = timecode?.split(":").map(Number);
    const totalSeconds =
      hours * 3600 +
      minutes * 60 +
      seconds +
      frames / Number(this.asset?.frameRate);
    return totalSeconds;
  }

  private isEventWithinVideoDuration(event: any): boolean {
    const tcInSec = this.timecodeToSeconds(event.tcIn);
    const tcOutSec = this.timecodeToSeconds(event.tcOut);
    const videoDuration = this.asset?.startTimeCode
      ? Number(this.asset?.duration) +
        getTimeInSecondsFromFrameRate(
          this.asset?.startTimeCode,
          Number(this.asset?.frameRate)
        )
      : Number(this.asset?.duration);
    const start = this.asset?.startTimeCode
      ? getTimeInSecondsFromFrameRate(
          this.asset.startTimeCode,
          Number(this.asset.frameRate)
        )
      : 0;
    return tcInSec >= start && tcOutSec <= videoDuration;
  }
}
