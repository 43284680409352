import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import {
  Project,
  ProjectReply,
  ProjectsDto,
  ProjectStatus,
  ReplyStatus,
  VoiceType,
} from "@models/textToSpeech/project.model";
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ProjectService } from "~/app/textToSpeech/project.service";
import { ReplyService } from "../../reply.service";
import { VideoJSDataService } from "~/app/shared/video-jsdata.service";
import { TranslateService } from "@ngx-translate/core";
import { appConfig } from "~/environments/app.config";
import { TextToSpeechService } from "../../textToSpeech.service";
import { LexiconGrapheme } from "@models/lexicon/lexicon.model";
import { environment } from "~/environments/environment";
import { LexiconService } from "~/app/lexicon/lexicon.service";
import {
  CreateAndStartProduction,
  ResponseFromApi,
} from "@models/textToSpeech/textToSpeech.model";
import { Subject } from "rxjs";
import {
  getTimeInSecondsFromFrameRate,
  matchWordOccurrences,
} from "~/utils/project";
import { ListItemAction } from "@models/shared/action-list.model";
import { AudioJsComponent } from "~/app/shared/components/audio-js/audio-js.component";
import { AssetService } from "~/app/asset/asset.service";
import { Asset } from "@models/asset/asset.model";

@Component({
  selector: "app-wav",
  templateUrl: "./wav.component.html",
  styleUrls: ["./wav.component.scss"],
})
export class WavComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() project: Project;
  @Input() isNotEditable: boolean;
  @Input() tabIndex: number;

  @ViewChild("previewAudio", { static: false })
  private preview: ElementRef;

  @ViewChildren(AudioJsComponent) audioComp: QueryList<any>;

  public isVisible = true;
  public isPreviewAudioAvailable = false;
  public isPreviewAudioPlaying = false;
  public isPreviewInProgress = false;
  public searchView = appConfig.project.wav.searchView;
  public currentlyPlayingReplyName = "";
  public previewAudioSource = "";
  public matchingOccurrences: number;
  public matchingOccurrencesMessage: string;
  public searchValue: string;
  public projectDto: ProjectsDto;
  public projectReplies: ProjectReply[];
  public projectRepliesBackup: ProjectReply[];
  public projectDetails: Project;
  public previewAndReplaceFormGroup: UntypedFormGroup;
  private previousReplaceWithValue = "";
  private searchStringChanged: Subject<string> = new Subject<string>();
  public searchTerm = "";
  public sticky = false;
  public replaceReplies: ProjectReply[] = [];
  private stickyDivPosition = -1;
  public removeEventLoader = false;
  public actions: ListItemAction[];
  public isUpdateXml: boolean;
  public showAdvanceFilters: boolean = false;
  public selectedAsset: Asset;
  public showVideoPlayer: boolean = false;
  public isSelectedAssetVideo: boolean = false;
  public videoPlayer: any;

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private replyService: ReplyService,
    private textToSpeechService: TextToSpeechService,
    private videoJSService: VideoJSDataService,
    private translateService: TranslateService,
    private lexiconService: LexiconService,
    private assetService: AssetService,
    private cdr: ChangeDetectorRef
  ) {
    this.videoJSService.currentVideoPlayer.subscribe((videoPlayer) => {
      this.videoPlayer = videoPlayer;
    });
    this.videoJSService.isPlayerVisible.subscribe(
      (visible) => (this.isVisible = visible)
    );
    this.videoJSService.currentlyPlayingReplyName.subscribe(
      (replyName) => (this.currentlyPlayingReplyName = replyName)
    );
    this.previewAndReplaceFormGroup = this.formBuilder.group({
      replace: [""],
      replaceWith: [""],
    });

    this.searchStringChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((model) => {
        this.searchValue = model;
        this.search();
      });
  }

  ngAfterViewChecked(): void {
    if (this.tabIndex === 1 && this.showVideoPlayer) {
      this.showVideoPlayer = false;
      this.cdr.detectChanges();
    }
  }

  @HostListener("window:scroll", ["$event"])
  handleOnScroll() {
    if (this.tabIndex === 0) {
      const stickyElement = document.getElementById("sticky");
      if (this.stickyDivPosition < 0) {
        this.stickyDivPosition = this.findYPosition(stickyElement);
      }
      this.sticky = pageYOffset >= this.stickyDivPosition;
    }
  }

  findYPosition(obj: any) {
    let currentPosition = 0;
    if (obj && obj.offsetParent && typeof obj.offsetParent !== "undefined") {
      while (obj.offsetParent) {
        currentPosition += obj.offsetTop;
        obj = obj.offsetParent;
      }
      currentPosition += obj.offsetTop;
    } else if (obj && obj.y) {
      currentPosition += obj.y;
    }
    return currentPosition;
  }

  ngOnInit() {
    this.searchView
      .filter((e) => e.isChecked === true)
      .map((e) => (e.isChecked = false));
    this.sortReplies();
    this.projectReplies = this.projectRepliesBackup = this.project.replies;
    this.setActions();
    if (this.project.voiceSetting == VoiceType.Synthetic) {
      this.actions.push({
        label: "dashboard.downloadSynthetic",
        icon: "download",
        disabled: !this.project.vdFilePath,
        callback: this.downloadSyntheticVoice.bind(this),
        src: this.project.vdFilePath,
      });
    }
    this.getSelectedAssetById();
    this.isNotEditable =
      this.isNotEditable ||
      (environment.project.nonEditableStatus.includes(
        this.project?.status as ProjectStatus
      ) &&
        !this.project?.errorMessage);
  }

  public sortReplies() {
    this.project?.replies?.sort(
      (eventA, eventB) =>
        getTimeInSecondsFromFrameRate(eventA.tcIn, this.project.frameRate) -
        getTimeInSecondsFromFrameRate(eventB.tcIn, this.project.frameRate)
    );
  }

  setActions() {
    this.actions = [
      {
        label: "replies.vd",
        icon: "audiotrack",
        disabled: !this.project.vdFilePath,
        callback: this.playVd.bind(this),
        src: this.project.vdFilePath,
      },
      {
        label: "replies.playMix",
        icon: "audiotrack",
        disabled:
          !(this.project?.vdFilePath && this.project?.stereoFilePath) ||
          !this.project?.audioFilePath,
        callback: this.playVd.bind(this),
        src: this.project?.audioFilePath,
      },
      {
        label: this.getLabelForMix(),
        icon: "settings",
        disabled: this.isMixDisabled(),
        callback: this.startOrRestartMix.bind(this),
      },
      {
        label: "replies.downloadScript",
        icon: "download",
        disabled: !this.project.ttmlFilePath,
        callback: this.download.bind(this),
        src: this.project.audioFilePath,
      },
    ];
  }

  private isMixDisabled() {
    return (
      !(this.project.vdFilePath && this.project.stereoFilePath) ||
      this.isNotEditable ||
      environment.project.nonEditableStatus.includes(
        this.project?.status as ProjectStatus
      )
    );
  }

  private getLabelForMix() {
    let label = "replies.startMix";
    if (this.project.audioFilePath) {
      label = "replies.reStartMix";
    }
    return label;
  }

  public downloadMix(): void {
    if (!!this.project.audioFilePath) {
      window.open(this.project.audioFilePath, "_blank");
    } else {
      this.readyForConcatenation();
    }
  }

  // start (or restart) mix
  startOrRestartMix() {
    const automatedMix = {
      stereoTrackFileUrl: this.project.stereoFilePath,
      vDTrackFileUrl: this.project.vdFilePath,
      projectName: this.project.name,
      uniqueProjectName: this.project.uniqueProjectName,
      fromUI: true,
    } as CreateAndStartProduction;
    this.buildProjectDto(true);
    this.textToSpeechService
      .generateVdFileAndProcessMix(this.projectDto)
      .subscribe(() => {});
    this.router.navigateByUrl("/projects");
  }

  public getSelectedAssetById() {
    if (this.project.videoAssetId) {
      this.assetService
        .getAsset(this.project.videoAssetId)
        .subscribe((asset) => {
          this.selectedAsset = asset?.id ? asset : null;
          this.isSelectedAssetVideo = this.selectedAsset.assetType === "video";
        });
    }
  }

  get isAssetProxiesGenerated(): boolean {
    return this.selectedAsset?.assetProxy?.length > 0 || false;
  }

  download() {
    window.open(
      this.project.ttmlFilePath + "?timestamp=" + new Date().getTime(),
      "_blank"
    );
  }

  onClickAction(e: any, action: ListItemAction) {
    if (action.callback) {
      action.callback(action);
    }
  }

  playVd(action: any) {
    this.videoJSService.updateVisibility(true);
    this.currentlyPlayingReplyName = this.project.name;
    this.videoJSService.updateFileSource(action.src);
    this.videoJSService.updateReplyPlay(false);
  }

  ngAfterViewInit(): void {
    this.registerReplaceValueChange();
    // hide pause icon
    this.preview.nativeElement.addEventListener("ended", () => {
      this.isPreviewAudioPlaying = false;
      this.isPreviewAudioAvailable = true;
    });
  }

  public onAdvanceFilterClick(): void {
    this.showAdvanceFilters = !this.showAdvanceFilters;
  }

  public onClickVideoBtn() {
    this.showVideoPlayer = !this.showVideoPlayer;
    if (this.videoPlayer) {
      this.videoPlayer.dispose();
      this.videoJSService.updateVideoPlayer(false);
    }
  }

  ngOnDestroy(): void {
    if (this.videoPlayer) {
      this.videoPlayer.dispose();
      this.videoJSService.updateVideoPlayer(false);
    }
    this.videoJSService.updateVisibility(false);
  }

  registerReplaceValueChange() {
    this.previewAndReplaceFormGroup.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(() => {
        const replace = this.previewAndReplaceFormGroup
          .get("replace")
          .value.trim();
        this.searchTerm = replace;
        if (
          replace &&
          replace !==
            this.previewAndReplaceFormGroup.get("replaceWith").value.trim()
        ) {
          this.matchingOccurrencesMessage = this.countNoOfOccurrences();
        } else {
          this.matchingOccurrences = 0;
        }
        if (
          this.previousReplaceWithValue.trim() !==
          this.previewAndReplaceFormGroup.get("replaceWith").value.trim()
        ) {
          this.previousReplaceWithValue = this.previewAndReplaceFormGroup
            .get("replaceWith")
            .value.trim();
          this.hidePreviewAudioIcons(); // change all icons
        }
      });
  }

  /* Count the number occurrences for replacing text. */
  countNoOfOccurrences() {
    this.matchingOccurrences = 0;
    this.replaceReplies = [];

    this.projectReplies.filter((reply) => {
      const matchedWords = matchWordOccurrences(
        reply.ttmlSegmentData,
        this.previewAndReplaceFormGroup.get("replace").value.trim()
      );
      if (matchedWords && matchedWords.length) {
        this.matchingOccurrences =
          this.matchingOccurrences + matchedWords.length;
        this.replaceReplies.push(reply);
      }
    });

    return `${this.matchingOccurrences} results`;
  }

  createPreview() {
    // audio already created any is available
    if (this.isPreviewAudioAvailable) {
      // change icons
      this.isPreviewAudioAvailable = !this.isPreviewAudioAvailable;
      this.isPreviewAudioPlaying = !this.isPreviewAudioPlaying;
      // now its time to pause the main player
      this.videoJSService.updateFileSource(null);
      setTimeout(() => {
        this.preview.nativeElement.play();
      }, 500);
    } else if (this.isPreviewAudioPlaying) {
      this.preview.nativeElement.pause();
      // icons
      this.isPreviewAudioPlaying = !this.isPreviewAudioPlaying;
      this.isPreviewAudioAvailable = !this.isPreviewAudioAvailable;
    } else {
      this.isPreviewInProgress = true;
      this.generateAudioPreview(); // audio not available, create audio -hide the preview button and display loader
    }
  }

  async updateLexeme(lexiconGrapheme: LexiconGrapheme) {
    this.lexiconService.addOrUpdateGrapheme(lexiconGrapheme).subscribe(
      (response: ResponseFromApi) => {
        if (response.isSuccess) {
          this.project.lexiconUri = response.data.fileUri;
        } else {
          alert(response.status);
        }
      },
      (error: HttpErrorResponse) => {
        alert(error.statusText);
      }
    );
  }

  async confirmGraphemeUpdate(
    lexiconGrapheme: LexiconGrapheme,
    response: ResponseFromApi
  ) {
    if (response && response.isSuccess && response.data.isGraphemeExists) {
      lexiconGrapheme.isGraphemeExists = true;
      const updateAlias = confirm(
        this.translateService.instant("wav.replaceLexiconWarning")
      );
      if (updateAlias) {
        await this.updateLexeme(lexiconGrapheme);
      }
    } else if (response && response.isSuccess && response.data.lexiconUri) {
      this.project.lexiconUri = response.data.lexiconUri;
    } else if (!response.isSuccess) {
      alert(response.status);
    }
  }

  buildLexiconGrapheme() {
    return {
      lexiconId: this.project.lexiconId,
      grapheme: this.previewAndReplaceFormGroup.get("replace").value.trim(),
      alias: this.previewAndReplaceFormGroup.get("replaceWith").value.trim(),
      example: environment.project.lexicon.graphemeExample.replace(
        "{projectName}",
        this.project.name
      ),
      isGraphemeExists: false,
    } as LexiconGrapheme;
  }

  async lexiconReplace() {
    if (this.project.lexiconId) {
      const lexiconGrapheme = this.buildLexiconGrapheme();
      this.lexiconService.addOrUpdateGrapheme(lexiconGrapheme).subscribe(
        async (data: ResponseFromApi) => {
          this.confirmGraphemeUpdate(lexiconGrapheme, data);
        },
        (error: HttpErrorResponse) => {
          alert(error.statusText);
        }
      );
    }
  }

  /* Returns list of replies which includes the replacing text.*/
  async buildRepliesToReplace() {
    const replaceWithText = this.previewAndReplaceFormGroup
      .get("replaceWith")
      .value.trim();
    const replaceRegex = new RegExp(
      this.previewAndReplaceFormGroup.get("replace").value.trim(),
      "g"
    );

    this.replaceReplies.map((e) => {
      e.status = ReplyStatus.Creating;
      e.isLongAudio = false;
      e.ttmlSegmentData = e.ttmlSegmentData.replace(
        replaceRegex,
        replaceWithText
      );
    });
    return this.replaceReplies;
  }

  async replace() {
    if (this.isReplaceTextExist() && this.matchingOccurrences) {
      const replaceReplies = await this.buildRepliesToReplace();
      replaceReplies.forEach(async (reply: ProjectReply) => {
        const replyIndex = this.projectReplies.findIndex(
          (e) => e.id === reply.id
        );
        this.projectReplies[replyIndex] = reply;
        await this.updateReply(this.projectReplies[replyIndex]);
      });
      await this.lexiconReplace();
    }
  }

  buildProjectPreview() {
    const project = this.project as unknown as ProjectsDto;
    project.uniqueProjectNames = [this.project.uniqueProjectName];
    project.projectNames = [""];
    project.isAutomatedSpeed = false;
    project.isRestarting = false;
    project.previewAudio = this.previewAndReplaceFormGroup
      .get("replaceWith")
      .value.trim();
    if (typeof project.usersToNotify === "string") {
      project.usersToNotify = (project.usersToNotify as undefined as string)
        .split(",")
        .map(Number)
        .filter((x) => !isNaN(x));
    }
    return project;
  }

  generateAudioPreview() {
    const project = this.buildProjectPreview();
    this.projectService.createPreviewAudio(project).subscribe(
      (audioPath: any) => {
        this.isPreviewInProgress = false;
        this.isPreviewAudioAvailable = true;
        this.preview.nativeElement.src = audioPath;
      },
      () => {
        this.isPreviewInProgress = false;
        this.isPreviewAudioAvailable = false;
        this.isPreviewAudioPlaying = false;
      }
    );
  }

  buildProjectDto(
    isDownloadOrMix: boolean,
    reply?: ProjectReply,
    isVoiceRecordingEnabled?: boolean
  ) {
    this.projectDto = {
      id: this.project.id,
      userId: this.project.userId,
      projectNames: [this.project.name],
      uniqueProjectNames: [this.project.uniqueProjectName],
      language: this.project.language,
      region: this.project.region,
      voice: this.project.voice,
      lexiconUri: this.project.lexiconUri,
      startTimeCode: this.project.startTimeCode,
      speedRate: isDownloadOrMix ? this.project.speedRate : reply.speedRate,
      usersToNotify: [this.project.userId],
      isRestarting: false,
      isAutomatedSpeed: this.project.isAutomatedSpeedRate || false,
      isAutomatedMix: this.project.isAutomatedMix,
      automatedSpeedRate: this.project.automatedSpeedRate || 0,
      replies: isDownloadOrMix ? this.projectRepliesBackup : [reply],
      frameRate: this.project.frameRate,
      stereoFilePath: this.project.stereoFilePath || "",
      voiceRecordingEnabled: isVoiceRecordingEnabled || false,
      ttmlFilePath: this.project.ttmlFilePath,
      isUpdateXml: this.isUpdateXml,
      isManualMix: isDownloadOrMix,
      voiceSetting: this.project.voiceSetting,
      automatedSoundCorrection: this.project.automatedSoundCorrection,
    };
  }

  updatedReply(index: number, reply: ProjectReply) {
    this.projectRepliesBackup[index] = reply;
    this.projectReplies[
      this.projectReplies.findIndex((e) => e.id === reply.id)
    ] = reply;
    this.search();
    this.updatePlaylist(index, reply);
  }

  // to update the playlist everytime the xml is updated.
  updatePlaylist(index: number, reply: ProjectReply) {
    this.videoJSService.playList.subscribe((playlist) => {
      if (playlist) {
        playlist[index].sources[0].src = reply.audioFilePath;
        playlist[index].tcIn = reply.tcIn;
      }
    });
  }

  async updateReply(reply: ProjectReply) {
    this.countNoOfOccurrences();
    this.isUpdateXml = reply.isUpdateXml;
    this.buildProjectDto(false, reply); // false: not clicked on Download or Mix button.
    const replyIndex = this.projectRepliesBackup.findIndex(
      (e) => e.id === reply.id
    );

    this.replyService.update(this.projectDto).subscribe(
      (data: ProjectReply) => {
        if (Object.keys(data).length === 0 && data.constructor === Object) {
          this.projectRepliesBackup[replyIndex].status = ReplyStatus.Error;
          this.projectRepliesBackup[replyIndex].error =
            "Api error. Requires deep troubleshooting";
        } else {
          if (data.error) {
            this.projectRepliesBackup[replyIndex].status = ReplyStatus.Error;
            this.projectRepliesBackup[replyIndex].error = data.error;
          } else {
            this.updatedReply(replyIndex, data);
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.projectRepliesBackup[replyIndex].status = ReplyStatus.Error;
        this.projectRepliesBackup[replyIndex].error = error.error.message;
      }
    );
  }

  processConcatenation() {
    this.buildProjectDto(true); // true: clicked on Download or Mix button.
    this.hidePlayer();
    this.textToSpeechService
      .generateVdFileAndProcessMix(this.projectDto)
      .subscribe();
    this.router.navigateByUrl("/projects");
  }

  readyForConcatenation() {
    if (this.projectRepliesBackup.some((e) => e.isFarTooLongAudio === true)) {
      return;
    } else if (this.projectRepliesBackup.some((e) => e.isLongAudio === true)) {
      const confirmed = confirm(
        this.translateService.instant("replies.AudioConcatenationWarning")
      );
      if (confirmed) {
        this.processConcatenation();
      }
    } else {
      this.processConcatenation();
    }
  }

  searchViewReplies(selectedView: string[]) {
    const durationReplies = this.projectRepliesBackup.filter((e) =>
      selectedView.includes(
        e.isLongAudio && !e.error && e.status !== ReplyStatus.Error
          ? "duration"
          : ""
      )
    );
    const errorReplies = this.projectRepliesBackup.filter((e) =>
      selectedView.includes(e.status === ReplyStatus.Error ? "error" : "")
    );
    const processingReplies = [
      ...this.projectRepliesBackup.filter((e) =>
        selectedView.includes(
          e.status !== ReplyStatus.Error && !e.isLongAudio ? "processing" : ""
        )
      ),
    ];

    return [
      ...new Set([...durationReplies, ...processingReplies, ...errorReplies]),
    ];
  }

  search() {
    const selectedView = this.searchView
      .filter((e) => e.isChecked === true)
      .map((a) => a.key);
    this.searchValue = this.searchValue
      ? this.searchValue.trim()
      : this.searchValue;

    if (this.searchValue && selectedView.length > 0) {
      const replies = this.searchViewReplies(selectedView);
      this.projectReplies = this.projectRepliesBackup.filter(
        (e) =>
          replies.includes(e) &&
          e.ttmlSegmentData
            .toLowerCase()
            .includes(this.searchValue.toLowerCase())
      );
    } else if (this.searchValue) {
      this.projectReplies = this.projectRepliesBackup.filter((e) =>
        e.ttmlSegmentData.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    } else if (selectedView.length > 0) {
      const replies = this.searchViewReplies(selectedView);
      this.projectReplies = replies.sort((a, b) => a.id - b.id);
    } else {
      this.projectReplies = this.projectRepliesBackup;
    }
  }

  onSearchChange(query: string) {
    this.searchStringChanged.next(query);
  }

  hidePlayer() {
    this.videoJSService.updateVisibility(false); // false, hides the player
    this.isVisible = false;
  }

  public isDownloadButtonEnabled() {
    return this.projectRepliesBackup.some((e) => e.isFarTooLongAudio === true);
  }

  hidePreviewAudioIcons() {
    this.isPreviewAudioAvailable = false;
    this.isPreviewAudioPlaying = false;
  }

  isReplaceTextExist() {
    const replaceWith = this.previewAndReplaceFormGroup
      .get("replaceWith")
      .value.trim();
    return (
      replaceWith !== "" &&
      this.previewAndReplaceFormGroup.get("replace").value.trim() !==
        replaceWith
    );
  }

  clearSearch() {
    this.searchValue = "";
    this.searchView
      .filter((e) => e.isChecked === true)
      .map((e) => (e.isChecked = false));
    this.projectReplies = this.projectRepliesBackup;
  }

  async removeEvent(reply: ProjectReply) {
    this.removeEventLoader = true;
    const replyIndex = this.projectReplies.findIndex((e) => e.id === reply.id);
    reply.isUpdateXml = this.isUpdateXml = true;
    this.buildProjectDto(false, this.projectReplies[replyIndex]);

    if (
      replyIndex > 0 &&
      this.projectReplies[replyIndex - 1].isFarTooLongAudio
    ) {
      this.projectReplies[replyIndex - 1].status = ReplyStatus.Creating;
      this.projectReplies[replyIndex - 1].nextTcIn =
        this.projectReplies[replyIndex].nextTcIn;
      this.projectDto.replies.push(this.projectReplies[replyIndex - 1]);
    }

    this.projectReplies = this.projectReplies.filter((e) => e.id !== reply.id);
    this.projectRepliesBackup = this.projectRepliesBackup.filter(
      (e) => e.id !== reply.id
    );

    this.replyService.removeEvent(reply.id, this.projectDto).subscribe(
      (data) => {
        if (data) {
          this.updatedReply(replyIndex - 1, data);
          this.removeEventLoader = false;
        }
      },
      (error: HttpErrorResponse) => {
        this.removeEventLoader = false;
        console.log(error.error.message);
      }
    );
  }

  async saveVoiceRecording(reply: ProjectReply) {
    this.buildProjectDto(false, reply, true); // false: not clicked on Download or Mix button.
    const replyIndex = this.projectRepliesBackup.findIndex(
      (e) => e.id === reply.id
    );
    const formData = new FormData();
    formData.append(
      "file",
      this.projectDto.replies[0].voiceRecording.get("audio-file")
    );
    formData.append("data", JSON.stringify(this.projectDto));
    this.replyService.saveVoiceRecording(formData).subscribe(
      (data: ProjectReply) => {
        data.voiceRecording = formData.get("file");
        this.updatedReply(replyIndex, data);
      },
      (error: HttpErrorResponse) => {
        this.projectRepliesBackup[replyIndex].status = ReplyStatus.Error;
        this.projectRepliesBackup[replyIndex].error = error.error.message;
      }
    );
  }

  get startTimeCode(): string {
    return this.project?.startTimeCode !== "00:00:00:00"
      ? this.project?.startTimeCode.replace(";", ":")
      : this.selectedAsset?.startTimeCode
      ? this.selectedAsset?.startTimeCode.replace(";", ":")
      : "00:00:00:00";
  }

  private downloadSyntheticVoice(): void {
    window.open(
      this.project.vdFilePath + "?timestamp=" + new Date().getTime(),
      "_blank"
    );
  }
}
