import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ProjectReply, ReplyStatus } from "@models/textToSpeech/project.model";
import { filter, switchMap } from "rxjs/operators";
import { ReplyService } from "~/app/textToSpeech/reply.service";
import { bufferToWave } from "~/utils/project";
import { VideoJSDataService } from "../../video-jsdata.service";
import { DialogConfirmWithAlertComponent } from "../dialog-confirm-with-alert/dialog-confirm-with-alert.component";

@Component({
  selector: "app-reply",
  templateUrl: "./reply.component.html",
  styleUrls: ["./reply.component.scss"],
})
export class ReplyComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  @Input() reply: ProjectReply;
  @Input() searchTerm: string;
  @Input() isNotEditable: boolean;
  @Output() updateReply = new EventEmitter<ProjectReply>();
  @Output() removeEvent = new EventEmitter<ProjectReply>();
  @Output() saveVoiceRecording = new EventEmitter<ProjectReply>();
  @ViewChildren("retakesContainer") retakesContainerElement: QueryList<any>;
  @ViewChildren("segment") segmentElement: QueryList<any>;
  @ViewChildren("segmentData") segmentDataElementList: QueryList<any>;
  @Input() replyIndex: number;
  @Input() replies: ProjectReply[] = [];
  @Input() projectName: string = null;
  @Input() projectFrameRate: number;
  @Input() humanVoiceRecorderId: string = undefined;

  @ViewChild("recordedAudio", { static: true })
  public recordedAudio: ElementRef;

  @ViewChild("output", { static: true })
  public output: ElementRef;

  public isVisible = false;
  public statusImage: string;
  public formGroup: UntypedFormGroup;
  public currentPlayingFileSource = "";
  public acceptedLongAudio: boolean;
  public replyStatus = ReplyStatus;
  public speedRate: number;
  public currentlyPlayingReplyName = "";

  public voiceRecording: any;
  public blob: any;

  public timeUpdate: NodeJS.Timeout;
  public showProgressSpinner = false;
  public showCountDown = false;
  public recordingTimeInSeconds = "00";
  public recordingTimeInMilliSeconds = "000";
  public recordingProgress = 0;

  constructor(
    private videoJSService: VideoJSDataService,
    private replyService: ReplyService,
    public dialog: MatDialog
  ) {
    this.videoJSService.sharedFileSource.subscribe(
      (source) => (this.currentPlayingFileSource = source)
    );
    this.videoJSService.isPlayerVisible.subscribe(
      (visible) => (this.isVisible = visible)
    );
    this.videoJSService.currentlyPlayingReplyName.subscribe(
      (replyName) => (this.currentlyPlayingReplyName = replyName)
    );
  }

  ngAfterContentChecked(): void {
    if (this.retakesContainerElement) {
      this.retakesContainerElement.forEach((div) => {
        this.segmentElement.forEach((childDiv) => {
          div.nativeElement.style.height =
            childDiv.nativeElement.offsetHeight + 15 + "px";
          div.nativeElement.style.minHeight = "76px";
        });
      });
    }
  }

  ngAfterViewInit(): void {
    let content = "";
    this.segmentDataElementList.forEach((div) => {
      const ttmlDiv = div.nativeElement;
      if (this.isNotEditable) {
        ttmlDiv.contentEditable = "false"; // prevent edit
        ttmlDiv.className = " disabled-color"; // adding grey as color to give the feel of disabled input
      }
      ttmlDiv.addEventListener("focus", () => {
        // get the latest content on the div
        content = this.extractTTMLData(ttmlDiv.innerHTML);
      });
      ttmlDiv.addEventListener("blur", () => {
        const updatedContent = this.extractTTMLData(ttmlDiv.innerHTML);
        // iff content is changed then request for the new audio
        if (updatedContent !== content) {
          this.onTextChange(updatedContent);
        }
      });
    });
    if (
      this.reply.voiceRecording &&
      this.reply.voiceRecording.name &&
      this.recordedAudio
    ) {
      this.recordedAudio.nativeElement.src = URL.createObjectURL(
        this.reply.voiceRecording
      );
    }
  }

  ngOnInit() {
    this.speedRate = this.reply.speedRate;
  }

  emitChanges() {
    if (!this.isNotEditable) {
      this.reply.status = ReplyStatus.Creating;
      this.reply.isLongAudio = this.reply.isFarTooLongAudio = false;
      this.reply.speedRate = Number(
        parseFloat(this.reply.speedRate.toString()).toFixed(2)
      );
      this.reply.ttmlSegmentData = this.reply.ttmlSegmentData.trim();
      this.updateReply.emit(this.reply);
    }
  }

  extractTTMLData(segmentData: any) {
    // return data without mark and other tags
    return segmentData
      .replaceAll("<mark>", "")
      .replaceAll("</mark>", "")
      .replaceAll("</div>", "")
      .replaceAll("<br>", "")
      .replaceAll('<font color="#000000"><span>', "")
      .replaceAll("</span></font>", "")
      .replaceAll("<div>", "\n")
      .trim();
  }

  onTextChange(segment?: string) {
    if (this.reply.ttmlSegmentData.trim()) {
      if (segment) {
        this.reply.ttmlSegmentData = segment.trim();
        this.reply.isUpdateXml = true;
      }
      this.emitChanges();
    }
  }

  onSpeedRateChange() {
    if (this.reply.speedRate && this.speedRate !== this.reply.speedRate) {
      this.reply.isUpdateXml = false;
      this.emitChanges();
    }
  }

  play(reply: ProjectReply) {
    this.videoJSService.updateReplyName(reply.tcIn);
    this.videoJSService.updateVisibility(true);
    this.videoJSService.updateFileSource(reply.audioFilePath);
    this.videoJSService.updateReplyPlay(true);
  }

  acceptLongAudio(reply: ProjectReply) {
    if (!this.isNotEditable) {
      this.acceptedLongAudio = true;
      reply.isLongAudio = false;
      reply.longAudioDuration = 0;
      this.replyService.updateLongAudio(reply).subscribe(
        () => {
          this.acceptedLongAudio = false;
        },
        (error: HttpErrorResponse) => {
          this.acceptedLongAudio = false;
          reply.status = ReplyStatus.Error;
          reply.error = error.statusText;
        }
      );
    }
  }

  playAsPlaylist(replyIndex: number) {
    const playList = this.replies.map((reply) => {
      return {
        sources: [
          {
            src: reply.audioFilePath,
            type: "audio/wav",
          },
        ],
        tcIn: reply.tcIn,
      };
    });

    this.videoJSService.updateReplyName(this.projectName);
    this.videoJSService.updateVisibility(true);
    this.videoJSService.addPlayList(playList, replyIndex);
    this.videoJSService.updateReplyPlay(true);
  }

  confirmCancelEvent() {
    const data = {
      message: "replies.removeEvent.message",
      title: "replies.removeEvent.title",
      confirmLabel: "replies.removeEvent.remove",
      cancelLabel: "replies.removeEvent.keep",
    };

    this.dialog
      .open(DialogConfirmWithAlertComponent, { data })
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(async (_) => this.removeEvent.emit(this.reply))
      )
      .subscribe();
  }

  // Prepend zeros to the digits in stopwatch
  public prependZero(time: any, length: number) {
    time = String(time); // stringify time
    return new Array(Math.max(length - time.length + 1, 0)).join("0") + time;
  }

  // Update time in stopwatch periodically
  async startCounter(totalMilliseconds: number, isProgressSpinner?: boolean) {
    const startTime = new Date(); // fetch current time
    startTime.setMilliseconds(startTime.getMilliseconds() + totalMilliseconds);

    this.timeUpdate = setInterval(() => {
      const endTime = new Date();
      const timeElapsed = startTime.getTime() - endTime.getTime(); // calculate the time elapsed in milliseconds

      // calculate seconds
      let seconds = parseInt(String(timeElapsed / 1000), 10);
      if (seconds > 60) {
        seconds %= 60;
      }

      // calculate milliseconds
      let milliseconds = timeElapsed;
      if (milliseconds > 1000) {
        milliseconds %= 1000;
      }

      if (isProgressSpinner) {
        this.recordingProgress =
          ((totalMilliseconds - timeElapsed) * 100) / totalMilliseconds;
      } else {
        // set the counter
        this.recordingTimeInSeconds = this.prependZero(seconds, 2);
        this.recordingTimeInMilliSeconds = this.prependZero(milliseconds, 3);
      }

      if (Number(seconds) <= 0 && Number(milliseconds) <= 0) {
        this.resetCounterToZero();
      }
    }, 1); // update time in stopwatch after every 1ms
  }

  resetCounterToZero() {
    clearInterval(this.timeUpdate);
    this.recordingTimeInSeconds = "00";
    this.recordingTimeInMilliSeconds = "000";
  }

  getTimeCodeDuration() {
    const differenceInFrames =
      this.convertTimeCodeToFrames(this.reply.tcOut) -
      this.convertTimeCodeToFrames(this.reply.tcIn);
    const milliSeconds = (differenceInFrames / this.projectFrameRate) * 1000;
    return milliSeconds;
  }

  convertTimeCodeToFrames(timeCode: string): number {
    const [hours, minutes, seconds, frames] = timeCode.split(":");
    const totalSeconds =
      Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
    const totalFrames = totalSeconds * this.projectFrameRate + Number(frames);
    return totalFrames;
  }

  public saveRecording() {
    const formData = new FormData();
    formData.append("audio-file", this.blob);
    this.reply.voiceRecording = formData;
    this.reply.status = ReplyStatus.Creating;
    this.reply.isLongAudio = this.reply.isFarTooLongAudio = false;
    this.saveVoiceRecording.emit(this.reply);
  }

  public async applySpeedrate() {
    this.recordedAudio.nativeElement.playbackRate = this.reply.speedRate;
    const audioData = await this.blob.arrayBuffer();

    const audioCtx = new AudioContext();

    audioCtx.decodeAudioData(audioData, (buffer) => {
      const offlineContext = new OfflineAudioContext(
        1,
        buffer.duration * 24000,
        24000
      );
      const offlineSource = offlineContext.createBufferSource();
      offlineSource.buffer = buffer;
      offlineSource.playbackRate.value = this.reply.speedRate;
      offlineSource.connect(offlineContext.destination);
      offlineSource.start();
      offlineContext.startRendering().then(async (renderedBuffer) => {
        this.blob = bufferToWave(renderedBuffer, offlineContext.length);
        this.recordedAudio.nativeElement.src = URL.createObjectURL(this.blob);
      });
    });
  }

  public formattedCount(): string {
    const count = this.replyIndex + 1;
    return count < 10 ? `0${count}` : `${count}`;
  }
}
