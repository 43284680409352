import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import {
  Colleague,
  Project,
  ProjectStatus,
  VoiceType,
} from "@models/textToSpeech/project.model";
import { ProjectService } from "~/app/textToSpeech/project.service";
import { ProjectDialogConfirmComponent } from "../../project-dialog-confirm/project-dialog-confirm.component";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ConnectWebSocketService } from "../../connectWebSocket.service";
import { catchError, debounceTime } from "rxjs/operators";
import * as moment from "moment-timezone";
import { environment } from "~/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { getLanguageRegionList } from "~/app/shared/languageRegion";
import { ManagementService } from "~/app/management/management.service";
import { Client } from "@models/template/template.model";
import { KeyValuePair } from "@models/textToSpeech/textToSpeech.model";
import { UserHelperService } from "~/app/shared/userHelper.service";
import { NavigationExtras, Router } from "@angular/router";
import { SuiteCaseComponent } from "~/app/shared/components/suite-case/suite-case.component";
import { Pagination } from "@models/shared/action-list.model";
import { CatalogAssetSelectionService } from "~/app/shared/catalog-assets-selection.service";
import { DialogConfirmShareProjectComponent } from "~/app/shared/components/dialog-confirm-share-project/dialog-confirm-share-project.component";
import { throwError } from "rxjs";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "100px" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ProjectsComponent
  extends SuiteCaseComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild("languageSelect", { static: false }) languageSelect: MatSelect;
  public selectedValue: string;
  public dataSource = new MatTableDataSource<Project>();
  @ViewChild("footerCard", { static: true, read: ElementRef }) footerCard: any;
  public dataSourceBackup: Project[];
  public columnsToDisplay = ["name"];
  public expandedElement: Project | null;
  public languageRegionList: any[] = [];
  public searchValue: string;
  public spinner: boolean;
  public allLanguageSelected = true;
  public searchGroup: UntypedFormGroup;
  public projectDeletionList: Project[] = [];
  public deletionListOpened = false;
  public defaultSortValue = environment.project.defaultProjectSorting;
  public defaultCreatedDate = environment.project.defaultCreatedTimeFilter;
  private isFirstLoad = true;
  public clients: Client[] = [];
  public colleagues: Colleague[] = [];
  public colleaguesBackup: Colleague[] = [];
  public defaultCreatedBy: number = null;
  public defaultProgress: string = null;
  public defaultVoice: string = null;
  private projectSettingsKey = "projectFilterSettings";
  public isFilesDownloadable: boolean;
  public showAdvancedFilter = false;
  public allVoices: string[] = [];
  public allStatus: string[] = [];
  public clearSearchDisabled = true;
  public completedStatus = ProjectStatus.ReadyToDownload;
  public voiceType: VoiceType;

  public pageSizeOptions = [25, 50, 100, 200];
  public totalRecords = 0;
  public totalPages = 0;
  public paginationInfo = {
    from: 0,
    startIndex: 0,
    endIndex: 0,
    pageSize: this.pageSizeOptions[0],
    isBackDisabled: true,
    isFirstDisabled: true,
    isEndDisabled: true,
    isNextDisabled: true,
  } as Pagination;

  public sortOptions: KeyValuePair[] = [
    { value: "creationDateTime", key: "dashboard.sortBy.lastCreated" },
    { value: "lastModificationDateTime", key: "dashboard.sortBy.lastModified" },
  ];

  public createdDateOptions: KeyValuePair[] = [
    { value: "all", key: "dashboard.creationDate.all" },
    { value: "24", key: "dashboard.creationDate.last24H" },
    { value: "48", key: "dashboard.creationDate.last48H" },
    { value: "24m", key: "dashboard.modifiedDate.last24H" },
    { value: "48m", key: "dashboard.modifiedDate.last48H" },
  ];

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    private formBuilder: UntypedFormBuilder,
    private connectWebSocketService: ConnectWebSocketService,
    private translateService: TranslateService,
    private managementService: ManagementService,
    private router: Router,
    private renderer: Renderer2,
    private assetSelectionService: CatalogAssetSelectionService
  ) {
    super();
    this.createSearchParameters();
  }

  createSearchParameters() {
    this.searchGroup = this.formBuilder.group({
      searchString: [""],
      language: [],
      statusWaitingForTTML: [false],
      statusRetake: [false],
      statusError: [false],
    });
  }

  ngOnInit() {
    this.languageRegionList = getLanguageRegionList();
    this.getColleagues();
    this.getByUserId();
    this.handleSocket();
    this.searchGroup
      .get("searchString")
      .valueChanges.pipe(debounceTime(500))
      .subscribe((x) => {
        if (this.dataSourceBackup) {
          this.filterProjects();
        }
      });
  }

  handleSocket() {
    this.connectWebSocketService.connectToProjectSocket();
    this.connectWebSocketService.socketMessages.subscribe(
      (updatedProject: string) => {
        if (!this.dataSource.data) {
          return;
        }
        const statusUpdatedProject = JSON.parse(updatedProject as string);
        const index = this.dataSource.data.findIndex(
          (project) =>
            project.uniqueProjectName === statusUpdatedProject.uniqueProjectName
        );
        if (index > -1) {
          if (
            !!statusUpdatedProject.editingBy ||
            statusUpdatedProject.editingBy === null
          ) {
            this.dataSource.data[index].editingBy =
              statusUpdatedProject.editingBy;
            return;
          }
          this.dataSource.data[index].status = statusUpdatedProject.status;
          this.dataSource.data[index].audioFilePath =
            statusUpdatedProject.audioFilePath;
          this.dataSource.data[index].vdFilePath =
            statusUpdatedProject.vdFilePath;
        }
      }
    );
  }

  filterProjects(isFilterByCreatedDateAll?: boolean) {
    // Search Filter
    const searchString = this.searchGroup.get("searchString").value;
    if (searchString && searchString.trim() !== "") {
      this.clearSearchDisabled = false;
      this.currentPageRecords = this.dataSourceBackup.filter((e) =>
        e.name.toLowerCase().includes(searchString.toLowerCase())
      );
    } else {
      this.currentPageRecords = this.dataSourceBackup.slice();
    }

    // Language Filter
    const language = this.searchGroup.get("language").value;
    if (language && language.length > 0) {
      this.currentPageRecords = this.currentPageRecords.filter((data) =>
        language.includes(data.language + "-" + data.region)
      );
    }

    // Created By Filter
    if (this.defaultCreatedBy) {
      this.currentPageRecords = this.currentPageRecords.filter(
        (data) => data.userId === this.defaultCreatedBy
      );
    }

    // Progress Filter
    const allString = this.translateService.instant(`dashboard.all`);
    const selectedProgress =
      this.defaultProgress === "DV created"
        ? "VD created"
        : this.defaultProgress;
    if (selectedProgress && selectedProgress !== allString) {
      this.currentPageRecords = this.currentPageRecords.filter(
        (data) => data.status === selectedProgress
      );
    }

    // Voice Filter
    if (this.defaultVoice && this.defaultVoice !== allString) {
      this.currentPageRecords = this.currentPageRecords.filter(
        (data) => data.voice === this.defaultVoice
      );
    }

    // Status Filter
    const ttmlStatusFilter = this.searchGroup.get("statusWaitingForTTML").value;
    const retakesStatusFilter = this.searchGroup.get("statusRetake").value;
    const errorStatusFilter = this.searchGroup.get("statusError").value;
    if (ttmlStatusFilter || retakesStatusFilter || errorStatusFilter) {
      const filterResponse: Project[] = [];
      this.currentPageRecords.forEach((data) => {
        const isPushed = false;
        if (ttmlStatusFilter && !isPushed) {
          if (
            data.status
              .toLowerCase()
              .includes(ProjectStatus.WaitForTTML.toLowerCase())
          ) {
            filterResponse.push(data);
          }
        }
        if (retakesStatusFilter && !isPushed) {
          if (data.status.toLowerCase().includes("retakes")) {
            filterResponse.push(data);
          }
        }
        if (errorStatusFilter && !isPushed) {
          if (data.errorMessage && data.errorMessage.trim() !== "") {
            filterResponse.push(data);
          }
        }
      });
      this.currentPageRecords = filterResponse;
    }

    // Created Date Filter
    if (!isFilterByCreatedDateAll) {
      this.filterByCreationDate();
    }

    this.resetPagination();
    this.initDataSource_Data(this.currentPageRecords, this.defaultIndexFrom);
  }

  private resetPagination(): void {
    this.paginationInfo = {
      from: 0,
      startIndex: 0,
      endIndex: 0,
      pageSize: this.paginationInfo.pageSize,
      isBackDisabled: true,
      isEndDisabled: true,
      isFirstDisabled: true,
      isNextDisabled: true,
    };
  }

  getByUserId() {
    this.spinner = true;

    this.projectService.getByUserId().subscribe(
      (data) => {
        const allString = this.translateService.instant(`dashboard.all`);
        this.allVoices.push(allString);
        this.allStatus.push(allString);
        if (data) {
          data.forEach((project) => {
            const languageNameInFull = environment.project.languages.list.find(
              (l) => l.value === project.lexiconLanguage
            );
            project.voice = project.voice.split("/")[1];
            project.lexiconLanguage = languageNameInFull
              ? languageNameInFull.key
              : null;
            this.projectDeletionList.forEach((projectToDelete) => {
              if (
                projectToDelete.uniqueProjectName === project.uniqueProjectName
              ) {
                project.isSelected = true;
              }
            });
            this.allVoices.push(project.voice);
            const statusToExlude = [
              ProjectStatus.RetakesToPerform,
              ProjectStatus.WaitForTTML,
            ];
            if (
              !statusToExlude.some((status) =>
                project.status.includes(status)
              ) &&
              project.status !== ""
            ) {
              this.allStatus.push(project.status);
            }
          });
          this.allVoices = Array.from(new Set(this.allVoices));
          this.allStatus = Array.from(new Set(this.allStatus)).map((status) =>
            status === "VD created" ? "DV created" : status
          );
          this.dataSourceBackup = data;
          this.filterProjects();
        }
      },
      () => {
        this.spinner = false;
      },
      () => {
        if (this.isFirstLoad) {
          this.searchGroup.patchValue({
            language: [
              ...this.languageRegionList.map(
                (languageRegion) => languageRegion.languageRegionValue
              ),
            ],
          });
          this.isFirstLoad = false;
        } else {
          this.filterProjects();
        }
        this.spinner = false;
        this.restoreSearch();
      }
    );
  }

  clearSearch() {
    this.languageRegionList.forEach((l) => (l.isChecked = false));
    this.allLanguageSelected = true;
    this.defaultCreatedDate = "all";
    this.defaultProgress = null;
    this.defaultVoice = null;
    this.searchGroup.patchValue({
      searchString: "",
      language: [
        ...this.languageRegionList.map(
          (languageRegion) => languageRegion.languageRegionValue
        ),
      ],
      statusWaitingForTTML: false,
      statusRetake: false,
      statusError: false,
    });
    this.defaultCreatedBy = null;
    sessionStorage.clear();
    this.filterProjects();
  }

  clearSearchString() {
    this.clearSearchDisabled = true;
    this.searchGroup.patchValue({
      searchString: "",
    });
    sessionStorage.clear();
    this.saveSearchState();
    this.filterProjects();
  }

  deleteConfirmDialog(isBatchDelete: boolean, project?: Project): void {
    let batchDelete: any;
    if (isBatchDelete) {
      const details = this.projectDeletionList.map((pro) => {
        return { uniqueName: pro.uniqueProjectName, name: pro.name };
      });
      batchDelete = {
        names: details.map((detail) => detail.name).join(","),
        uniqueProjectName: details.map((detail) => detail.uniqueName).join(","),
      };
    }
    const dialogRef = this.dialog.open(ProjectDialogConfirmComponent, {
      width: "775px",
      height: "575px",
      data: {
        projectData: isBatchDelete
          ? { ...batchDelete, isBatchDelete: isBatchDelete }
          : project,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getByUserId();
        this.projectDeletionList = [];
        this.deletionListOpened = false;
      }
    });
  }

  public download(url: string) {
    window.open(url, "_blank");
  }

  IsSpaceBarPressed(e: any) {
    if (e.keyCode === 32) {
      this.clearSearch();
    }
  }

  updateEditingBy(uniqueProjectName: string, editingBy: string) {
    if (!editingBy) {
      this.projectService
        .updateEditingBy(uniqueProjectName, "edit")
        .subscribe();
    }
  }

  languageRegionOptionClick() {
    let newStatus = true;
    this.languageSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allLanguageSelected = newStatus;
  }

  showProjectDeletionList() {
    if (this.projectDeletionList.length) {
      this.dataSource.data = this.projectDeletionList;
      this.deletionListOpened = !this.deletionListOpened;
    }
  }

  hideProjectDeletionList() {
    this.deletionListOpened = !this.deletionListOpened;
    this.getByUserId();
  }

  projectSelection(project: Project) {
    if (!project.isSelected) {
      if (
        this.projectDeletionList.length <
        environment.project.batchDeletionAllowed
      ) {
        this.projectDeletionList.push(project);
        project.isSelected = !project.isSelected;
      } else {
        alert(
          this.translateService.instant(`dashboard.batchDeletionAllowedWarning`)
        );
      }
    } else {
      // remove project from deletion list
      const index = this.projectDeletionList.findIndex(
        (p) => p.uniqueProjectName === project.uniqueProjectName
      );
      this.projectDeletionList.splice(index, 1);
      if (this.deletionListOpened) {
        this.dataSource.data = this.projectDeletionList;
      }
      if (this.projectDeletionList.length === 0 && this.deletionListOpened) {
        this.deletionListOpened = !this.deletionListOpened;
        this.dataSource.data = undefined;
        this.getByUserId();
      }
      project.isSelected = !project.isSelected;
    }

    this.isFilesDownloadable = this.projectDeletionList.some(
      (proj: Project) => !!proj.audioFilePath
    );
  }

  batchDelete() {
    if (this.projectDeletionList.length) {
      // true indicates batch delete operation
      this.deleteConfirmDialog(true);
    }
  }

  bulkDownload() {
    this.projectDeletionList.forEach((project: Project) => {
      if (project.audioFilePath) {
        this.download(project.audioFilePath);
      }
    });
  }

  sortProjects(isCalledFromUI: boolean = false) {
    if (this.defaultSortValue === environment.project.defaultProjectSorting) {
      this.currentPageRecords = this.currentPageRecords
        .sortBy("creationDateTime")
        .reverse();
    } else {
      this.currentPageRecords = this.currentPageRecords
        .sortBy("lastModificationDateTime")
        .reverse();
    }

    if (isCalledFromUI) {
      this.initDataSource_Data(this.currentPageRecords, this.defaultIndexFrom);
    }
  }

  filterByCreationDate() {
    if (this.defaultCreatedDate === "all") {
      this.filterProjects(true);
    } else if (this.defaultCreatedDate.includes("m")) {
      // modification filter
      const duration = this.defaultCreatedDate.substring(
        0,
        this.defaultCreatedDate.length - 1
      );
      const modifiedDateFilter = this.currentPageRecords.filter((p) => {
        return (
          moment().diff(moment(p.lastModificationDateTime), "hours") <=
          +duration
        );
      });
      this.currentPageRecords = modifiedDateFilter;
    } else {
      // will handle the creation filter
      const creationDateFilter = this.currentPageRecords.filter((p) => {
        return (
          moment().diff(moment(p.creationDateTime), "hours") <=
          +this.defaultCreatedDate
        );
      });
      this.currentPageRecords = creationDateFilter;
    }
  }

  ngOnDestroy() {
    this.connectWebSocketService.socketMessages.unsubscribe();
  }

  getColleagues() {
    this.projectService.getColleague().subscribe((data) => {
      if (data) {
        const all = {
          id: 0,
          name: this.translateService.instant(`dashboard.all`),
        };
        this.colleaguesBackup = this.colleagues = data.sortBy("name");
        this.colleagues.forEach((user: Colleague, i) => {
          if (user.id === UserHelperService.getUserIdFromLocalStorage()) {
            user.name = "Me";
            this.colleagues.splice(i, 1);
            this.colleagues.unshift(user);
          }
        });
        data.splice(0, 0, all);
      }
    });
  }

  isLexiconAvailable(lexiconName?: string) {
    return lexiconName && lexiconName !== "";
  }

  public navigateToProjectDetails(projectId: number) {
    this.saveSearchState();
    this.clearSelectedAssetFromStorage();
    const navExtra: NavigationExtras = {
      state: { tabIndex: 1 },
    };
    this.router.navigate([`projects/edition/${projectId}`], navExtra);
  }

  public navigateToProjectPage(projectId: number, pageId: number) {
    this.saveSearchState();
    this.clearSelectedAssetFromStorage();
    const navExtra: NavigationExtras = {
      state: { pageId: pageId },
    };
    this.router.navigate([`projects/edition/${projectId}`], navExtra);
  }

  public navigateToProjectRetakes(projectId: number) {
    this.saveSearchState();
    this.clearSelectedAssetFromStorage();
    this.router.navigate([`projects/edition/${projectId}`]);
  }

  navigateToProjectCreation() {
    this.saveSearchState();
    this.clearSelectedAssetFromStorage();
    this.router.navigate([`projects/creation`]);
  }

  private clearSelectedAssetFromStorage(): void {
    sessionStorage.removeItem("draftProjectData");
    this.projectService.onChangeFileUploadContexts([]);
    this.assetSelectionService.removeSelection();
  }

  protected saveSearchState(): void {
    const lastFilter = this.searchGroup.value;
    const pageSettings = JSON.stringify({
      filter: lastFilter,
      orderBy: this.defaultSortValue,
      createdDateTime: this.defaultCreatedDate,
      progress: this.defaultProgress,
      voice: this.defaultVoice,
      createdBy: this.defaultCreatedBy,
    });
    sessionStorage.setItem(this.projectSettingsKey, pageSettings);
  }

  restoreSearch() {
    const settings = sessionStorage[this.projectSettingsKey];
    if (settings) {
      const settingsToRestore = JSON.parse(
        sessionStorage[this.projectSettingsKey]
      );
      this.searchGroup.patchValue(settingsToRestore.filter);
      this.defaultProgress = settingsToRestore.progress;
      this.defaultVoice = settingsToRestore.voice;
      this.defaultCreatedBy = settingsToRestore.createdBy;
      this.defaultCreatedDate = settingsToRestore.createdDateTime;
      this.defaultSortValue = settingsToRestore.orderBy;
      this.allLanguageSelected =
        settingsToRestore.filter.language.length ===
        this.languageRegionList.length;
    }
  }

  public pageChanged(paginationInfo: Pagination): void {
    this.paginationInfo = paginationInfo;
    this.defaultPerPageOptionsValue = paginationInfo.pageSize;
    this.initDataSource_Data(this.currentPageRecords, paginationInfo.from);

    if (scrollTo) {
      this.titleResults?.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  public changeFrom(from: number, scrollTo?: boolean): void {
    this.initDataSource_Data(this.currentPageRecords, from);

    if (scrollTo) {
      this.titleResults?.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  public onChangeOfResultsPerPage() {
    this.initDataSource_Data(this.currentPageRecords, this.defaultIndexFrom);
  }

  public initDataSource_Data(projects: Project[], indexFrom: number) {
    this.currentPageRecords = projects;
    this.responseResults = this.totalRecords = projects.length || 0;
    this.indexFrom = indexFrom;
    this.sortProjects();
    this.dataSource.data =
      this.currentPageRecords.slice(
        indexFrom,
        indexFrom + this.defaultPerPageOptionsValue
      ) || undefined;

    if (this.dataSource.data.length) {
      this.totalPages = Math.ceil(
        this.totalRecords / this.paginationInfo.pageSize
      );

      if (
        (this.paginationInfo.startIndex || 0) + this.dataSource.data.length <
        this.totalRecords
      ) {
        this.paginationInfo.isEndDisabled = false;
        this.paginationInfo.isNextDisabled = false;
      } else {
        this.paginationInfo.isEndDisabled = true;
        this.paginationInfo.isNextDisabled = true;
      }

      this.paginationInfo.startIndex =
        this.paginationInfo.startIndex === 0
          ? 1
          : this.paginationInfo.startIndex;
      this.paginationInfo.endIndex =
        this.totalRecords < this.paginationInfo.pageSize
          ? this.totalRecords
          : this.paginationInfo.endIndex === 0
          ? this.paginationInfo.pageSize
          : this.paginationInfo.endIndex;
    }
  }

  ngAfterViewChecked() {
    if (this.footerCard) {
      this.setFooterPosition(this.footerCard, this.renderer);
    }
  }

  public setFooterPosition(footCard: any, renderer: Renderer2) {
    const maxScroll =
      document.documentElement.scrollHeight -
      document.documentElement.offsetHeight;
    const currentScroll = document.documentElement.scrollTop;
    const cdkOverlay = document.querySelectorAll(
      ".cdk-overlay-backdrop, .cdk-overlay-backdrop-showing"
    );
    const matSelectOrMenu = document.querySelectorAll(
      ".mat-select-panel-wrap, .mat-menu-panel"
    );

    const footerHeight =
      cdkOverlay.length === 0 ||
      matSelectOrMenu.length !== 0 ||
      cdkOverlay.length
        ? 65
        : 0;
    renderer.setStyle(
      footCard.nativeElement.firstElementChild,
      "bottom",
      Math.max(0, footerHeight - (maxScroll - currentScroll)) + "px"
    );

    if (footerHeight === footerHeight - (maxScroll - currentScroll)) {
      renderer.setStyle(
        footCard.nativeElement.firstElementChild,
        "bottom",
        Math.max(65) + "px"
      );
    } else if (footerHeight < footerHeight - (maxScroll - currentScroll)) {
      renderer.setStyle(
        footCard.nativeElement.firstElementChild,
        "bottom",
        Math.max(0) + "px"
      );
    }
  }

  public getStatus(status: string): string {
    if (status.toLowerCase().includes("retakes")) {
      return "dashboard.scriptCreated";
    }
    if (
      status.toLowerCase().includes(ProjectStatus.ReadyToDownload.toLowerCase())
    ) {
      return "dashboard.completed";
    }
    if (status.toLowerCase().includes(ProjectStatus.Mixing.toLowerCase())) {
      return "dashboard.mixInProgress";
    }
    return status === ProjectStatus.VdCreated ? "replies.dvCreated" : status;
  }

  public getClass(status: string): string {
    if (
      status.toLowerCase().includes(ProjectStatus.ReadyToDownload.toLowerCase())
    ) {
      return "text-success";
    }
    return "in-progress";
  }

  public getRetake(status: string): string {
    if (status && status.toLowerCase().includes("retakes")) {
      return status.split(" ")[0];
    }
    return null;
  }

  public onAdvanceFilterClick(): void {
    this.showAdvancedFilter = !this.showAdvancedFilter;
  }

  public isAllowDownloadingSyntheticOrVoiceRecording(
    project: Project
  ): boolean {
    const hasVdAndStereoFilePaths =
      project.vdFilePath && project.stereoFilePath;
    const isVoicer = project.voiceSetting === VoiceType.Voicer;
    const isSynthetic =
      project.voiceSetting === VoiceType.Synthetic && !!project.vdFilePath;
    return (hasVdAndStereoFilePaths && isVoicer) || isSynthetic;
  }

  public showShareProjectDialog(
    uniqueProjectName: string,
    sharedWith?: number
  ): void {
    this.spinner = true;
    const getUsersObs = this.managementService.getUsers();
    getUsersObs.subscribe((users: any) => {
      this.spinner = false;
      const data = {
        title: "scriptWriting.share.title",
        subHeading: "scriptWriting.share.subHeading",
        cancel: "scriptWriting.share.cancel",
        confirm: "scriptWriting.share.confirm",
        list: users.map((user: any) => ({
          value: user.id,
          key: user.name,
        })),
        defaultValue: sharedWith ? sharedWith?.toString() : "",
      };
      this.dialog
        .open(DialogConfirmShareProjectComponent, {
          width: "550px",
          data: data,
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(({ value }) => {
          if (value) {
            this.spinner = true;
            this.projectService
              .shareProjectForVoicer(+value, uniqueProjectName)
              .pipe(catchError((err: any) => throwError(err)))
              .subscribe()
              .add(() => {
                this.getByUserId();
              });
          }
        });
    });
  }
}
