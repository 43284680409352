import { PasswordCredential } from '../account/account.model';
import { AppModule } from '../enum/appModule';
import { Omit } from '../../utils/object';
import { SortParam } from '../enum/elasticsearchCriteria';
import { Tenant } from '@models/tenant/tenant.model';

export enum ViewMode {
    List = 'list',
    Grid = 'grid'
}

export interface User {
    id?: number;
    name: string;
    userType: string;
    email: string;
    language?: string;
    createdBy?: string;
    creationTime?: Date;
    lastModificationTime?: string | Date;
    avatarUri?: string;
    host?: string;
    isSelected?: boolean;
    tenants?: Tenant[];
}

export interface UserTenant {
    id: number;
    tenantId: string;
    userId: number;
    tenant?: Tenant;
    createdAt?: Date;
    updatedAt?: Date;
}

/**
  * Part of user exposed to the UI
  * Additional properties are read-only fields that can't be modified by UI
  */
export interface UserForAdmin extends Pick<User, 'id' | 'name' | 'lastModificationTime' | 'email'> {
    hasCredentials: boolean;
    wrongPasswordBlocked: boolean;
    lastConnections: Date[];
    resetLinkSent: boolean;
}

/**
 * Raw user, as saved in the database, without the properties related to current session.
 * WARNING: UI should never receive this version of the object.
 */
export interface UserWithCredential extends Omit<User, 'email' | 'current'> {
    credential: PasswordCredential; // UI should _never_ receive that. It would be a serious security breach
}

export interface UserWithCredentialHistory extends UserWithCredential {
    userId: string;
}

export enum SupportedLanguages {
    French = 'fr',
    English = 'en'
}

export interface UserPreferences {
    language: SupportedLanguages;
    landingModule: AppModule;
    viewMode: ViewMode;
    resultsPerPage: number;
    thumbnailsVisible: boolean;
}

export const userSortOptions = {
    name: { 'fullName.lowercase-normalizer': 'asc' } as SortParam,
    lastModification: { 'lastModificationTime': 'desc' } as SortParam
};

export enum UserStatuses {
    Pending = 'pending',
    Active = 'active',
    Blocked = 'blocked',
    Deactivated = 'deactivated'
}

export interface UserForProject {
    id: number;
    name: string;
    userType: string;
    email: string;
    isSelected: boolean;
}

export interface UserToNotify {
    id?: number;
    projectId: number;
    userId: number;
}
